<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3 mobSticky">
                        <div class="getStart bg-white boxed box-shadow guide_lines mobScroll" id="getstarted">
                            <div class="repeatListFlex">
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'ZuluTrade'" :class="(ZuluTradeTabs == 'ZuluTrade')?'active':''" class="js-anchor-link d-flex" href="#affiliates">{{$t('zuluTradeTabs.Tab1')}}</a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Performance'" :class="(ZuluTradeTabs == 'Performance')?'active':''" class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('zuluTradeTabs.Tab2')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Payments'" :class="(ZuluTradeTabs == 'Payments')?'active':''" class="js-anchor-link d-flex" href="#Payments">{{$t('zuluTradeTabs.Tab3')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Terminals'" :class="(ZuluTradeTabs == 'Terminals')?'active':''" class="js-anchor-link d-flex" href="#supportAssistance">{{$t('zuluTradeTabs.Tab4')}} </a>
                                </div>
                                <div class="repeatList">
                                    <a @click.prevent="ZuluTradeTabs = 'Suspension'" :class="(ZuluTradeTabs == 'Suspension')?'active':''" class="js-anchor-link d-flex" href="#suspension">{{$t('zuluTradeTabs.Tab5')}} </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="bg-white boxed box-shadow p-md-4">
                            <!-- Main item -->
                            <section class="inner-box" id="affiliates">
                                <h1 class="tag f-25 medium border-bottom pb-1 mb-2">{{$t('zuluTrade_leaders.tab_title')}}</h1>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A1"
                                        role="button" aria-expanded="true" aria-controls="collapseExample">{{$t('zuluTrade_leaders.tab_heading')}}</a>
                                    <div class="collapse selected show" id="A1" data-bs-parent="#affiliates">
                                        <p> {{$t('zuluTrade_leaders.content1')}}</p>
                                            <p> {{$t('zuluTrade_leaders.content2')}}</p>
                                        
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A2"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('zuluTrade_leaders.tab_heading1')}}</a>
                                    <div class="collapse selected" id="A2" data-bs-parent="#affiliates">
                                        <p v-html="$t('zuluTrade_leaders.content3')"></p>
                                            <p>{{$t('zuluTrade_leaders.content4')}} </p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A3"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('zuluTrade_leaders.tab_heading2')}}</a>
                                    <div class="collapse selected" id="A3"  data-bs-parent="#affiliates">
                                        <p>{{$t('zuluTrade_leaders.content5')}} </p>
                                        <strong class="mb-1 d-block">{{$t('zuluTrade_leaders.content6')}}</strong>
                                        <p>{{$t('zuluTrade_leaders.content7')}} </p>
                                        <strong class="mb-1 d-block">{{$t('zuluTrade_leaders.content8')}}</strong>
                                        <p>{{$t('zuluTrade_leaders.content9')}} </p>
                                        <strong class="mb-1 d-block">{{$t('zuluTrade_leaders.content10')}}</strong>
                                        <p>{{$t('zuluTrade_leaders.content11')}} </p>
                                        <strong class="mb-1 d-block">{{$t('zuluTrade_leaders.content12')}} </strong>
                                        <p>{{$t('zuluTrade_leaders.content13')}} </p>
                                    </div>
                                </div>
                            </section>

                            <!-- Main item -->
                            <section class="inner-box fullList" id="affiliateAccount">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('performance_indicators.tab_title1')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <p>{{$t('performance_indicators.content14')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('performance_indicators.content15')}}</li>
                                        <li>{{$t('performance_indicators.content16')}}</li>
                                        <li>{{$t('performance_indicators.content17')}}</li>
                                        <li>{{$t('performance_indicators.content18')}}</li>
                                    </ul>
                                    <p> {{$t('performance_indicators.content19')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('performance_indicators.content20')}}</li>
                                        <li>{{$t('performance_indicators.content21')}}</li>
                                        <li>{{$t('performance_indicators.content22')}}</li>
                                    </ul>
                                    <p>{{$t('performance_indicators.content23')}}</p>
                                    <p>{{$t('performance_indicators.content24')}}</p>
                                    <p>{{$t('performance_indicators.content25')}}</p>
                                    <ul class="dotList">
                                        <li> {{$t('performance_indicators.content26')}}</li>
                                        <li> {{$t('performance_indicators.content27')}}</li>
                                        <li> {{$t('performance_indicators.content28')}}</li>
                                        <li> {{$t('performance_indicators.content29')}}</li>
                                        <li> {{$t('performance_indicators.content30')}}</li>
                                        <li> {{$t('performance_indicators.content31')}}</li>
                                        <li> {{$t('performance_indicators.content32')}}</li>
                                        <li> {{$t('performance_indicators.content33')}}</li>
                                        <li> {{$t('performance_indicators.content34')}}</li>
                                    </ul>
                                    <p>{{$t('performance_indicators.content35')}}</p>
                                </div>
                            </section>

                            <!-- Main item -->
                            <section class="inner-box fullList" id="Payments">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('general_payments.tab_title2')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <strong class="mb-1 d-block">{{$t('general_payments.content93')}}</strong>
                                    <p>{{$t('general_payments.content36')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('general_payments.content37')}}</li>
                                        <li>{{$t('general_payments.content38')}}</li>
                                        <li>{{$t('general_payments.content39')}}</li>
                                    </ul>
                                    <strong class="mb-1 d-block">{{$t('general_payments.content40')}}</strong>
                                    <p>{{$t('general_payments.content41')}}</p>
                                    <strong class="mb-1 d-block">{{$t('general_payments.content42')}}</strong>
                                    <p>{{$t('general_payments.content43')}}</p>
                                    <p>{{$t('general_payments.content44')}}</p>
                                    <p>{{$t('general_payments.content45')}}</p>
                                    <p>{{$t('general_payments.content46')}}</p>
                                    <p>{{$t('general_payments.content47')}}</p>
                                    <p>{{$t('general_payments.content48')}}</p>
                                    <p>{{$t('general_payments.content49')}}</p>
                                    <p>{{$t('general_payments.content50')}}</p>
                                    <p>{{$t('general_payments.content51')}}</p>
                                    <p>{{$t('general_payments.content52')}}</p>
                                    <p>{{$t('general_payments.content53')}}</p>
                                    <p>{{$t('general_payments.content54')}}</p>
                                    <p>{{$t('general_payments.content55')}}</p>
                                    <p>{{$t('general_payments.content56')}}</p>
                                    <p>{{$t('general_payments.content57')}}</p>
                                    <p>{{$t('general_payments.content58')}}</p>
                                    <p>{{$t('general_payments.content59')}}</p>
                                    <p>{{$t('general_payments.content60')}}</p>
                                    <p>{{$t('general_payments.content61')}}</p>
                                    <p>{{$t('general_payments.content62')}}</p>
                                    <p>{{$t('general_payments.content63')}}</p>
                                    <p>{{$t('general_payments.content64')}}</p>
                                    <p>{{$t('general_payments.content65')}}</p>
                                    <p>{{$t('general_payments.content66')}}</p>
                                    <p>{{$t('general_payments.content67')}}</p>
                                    <p>{{$t('general_payments.content68')}}</p>
                                    <p>{{$t('general_payments.content69')}}</p>
                                    <p>{{$t('general_payments.content70')}}</p>
                                    <p>{{$t('general_payments.content71')}}</p>
                                    <p>{{$t('general_payments.content72')}}</p>
                                    <p>{{$t('general_payments.content73')}}</p>
                                    <p>{{$t('general_payments.content74')}}</p>
                                    <p>{{$t('general_payments.content75')}}</p>
                                    <p>{{$t('general_payments.content76')}}</p>
                                    <p>{{$t('general_payments.content77')}}</p>
                                    <p>{{$t('general_payments.content78')}}</p>
                                    <strong class="mb-1 d-block" v-html="$t('general_payments.content94')"></strong>
                                    <p>{{$t('general_payments.content79')}} </p>
                                    <ul class="dotList">
                                        <li>{{$t('general_payments.content80')}}</li>
                                        <li>{{$t('general_payments.content81')}}</li>
                                        <li>{{$t('general_payments.content82')}}</li>
                                    </ul>
                                    <p>{{$t('general_payments.content83')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('general_payments.content84')}}</li>
                                        <li>{{$t('general_payments.content85')}}</li>
                                        <li>{{$t('general_payments.content86')}}</li>
                                        <li>{{$t('general_payments.content87')}}</li>
                                        <li>{{$t('general_payments.content88')}}</li>
                                        <li>{{$t('general_payments.content89')}}</li>
                                        <li>{{$t('general_payments.content90')}}</li>
                                        <li>{{$t('general_payments.content91')}}</li>
                                        <li>{{$t('general_payments.content92')}}</li>
                                    </ul>
                                </div>
                            </section>
                            <!-- Main item -->
                            <section class="inner-box fullList" id="supportAssistance">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('trading_from.tab_title3')}}</h3>
                                <div class="selected support_sec">
                                    <p>{{$t('trading_from.content123')}}</p>
                                    <p>{{$t('trading_from.content124')}}</p>
                                    <p>{{$t('trading_from.content95')}}</p>
                                    <p>{{$t('trading_from.content96')}}</p>
                                    <p>{{$t('trading_from.content97')}}</p>
                                    <p>{{$t('trading_from.content98')}}</p>
                                    <p>{{$t('trading_from.content99')}}</p>
                                    <p>{{$t('trading_from.content100')}}</p>
                                    <p>{{$t('trading_from.content101')}}</p>
                                    <p>{{$t('trading_from.content102')}}</p>
                                    <p>{{$t('trading_from.content103')}}</p>
                                    <strong class="mb-1 d-block">{{$t('trading_from.content132')}}</strong>
                                    <p>{{$t('trading_from.content104')}}</p>
                                    <p>{{$t('trading_from.content105')}}</p>
                                    <p>{{$t('trading_from.content106')}}</p>
                                    <!-- <p>{{$t('trading_from.content107')}} </p> -->
                                    <p>{{$t('trading_from.content108')}}</p>
                                    <p>{{$t('trading_from.content109')}}</p>
                                    <strong class="mb-1 d-block">{{$t('trading_from.content110')}}</strong>
                                    <p>{{$t('trading_from.content111')}}</p>
                                    <strong class="mb-1 d-block">{{$t('trading_from.content112')}}</strong>
                                    <ul class="dotCircle maker">
                                        <li>
                                            <strong class="mb-1 d-block">{{$t('trading_from.content113')}}</strong>
                                            <span>{{$t('trading_from.content114')}}</span>
                                        </li>
                                        <li>
                                            <strong class="mb-1 d-block">{{$t('trading_from.content115')}} </strong>
                                            <span>{{$t('trading_from.content116')}}</span>
                                        </li>
                                        <li>
                                            <strong class="mb-1 d-block">{{$t('trading_from.content117')}}</strong>
                                            <span>{{$t('trading_from.content118')}}</span>
                                        </li>
                                        <li><strong class="mb-1 d-block">{{$t('trading_from.content119')}}</strong>
                                            <span>{{$t('trading_from.content120')}}</span>
                                        </li>
                                        <li>
                                            <strong class="mb-1 d-block">{{$t('trading_from.content121')}}</strong>
                                            <span>{{$t('trading_from.content122')}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            <!-- Main item -->
                            <section class="inner-box fullList" id="suspension">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('suspension.tab_title4')}}</h3>
                                <div class="selected support_sec">
                                   <p>{{$t('suspension.content125')}} </p>
                                   <strong class="mb-1 d-block">{{$t('suspension.content126')}}</strong>
                                   <p> {{$t('suspension.content127')}} </p>
                                    <strong class="mb-1 d-block">{{$t('suspension.content128')}} </strong>
                                    <ul class="dotCircle maker">
                                        <li>{{$t('suspension.content129')}}</li>
                                        <li>{{$t('suspension.content130')}}</li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
 
</template>
<script>
import $ from 'jquery'
export default {
    setup() {

    },
    data() {
        return {
            ZuluTradeTabs : 'ZuluTrade'
        };
    },
    mounted() {
        $('.js-anchor-link').click(function(e){
            e.preventDefault();
            var target = $($(this).attr('href'));
            if(target.length){
                var scrollTo = target.offset().top;
                $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
            }
        });

        // Get all sections that have an ID defined
        const sections = document.querySelectorAll("section[id]");

        // Add an event listener listening for scroll
        window.addEventListener("scroll", navHighlighter);

        function navHighlighter() {
        
        // Get current scroll position
        let scrollY = window.pageYOffset;
        
        // Now we loop through sections to get height, top and ID values for each
        sections.forEach(current => {
            const sectionHeight = current.offsetHeight;
            const sectionTop = current.offsetTop - 50;
            var sectionId = current.getAttribute("id");
            
            /*
            - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
            - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
            */
            if (
                scrollY > sectionTop &&
                scrollY <= sectionTop + sectionHeight
            ){
                document.querySelector(".repeatList a[href*=" + sectionId + "]").classList.add("active");
            } else {
                document.querySelector(".repeatList a[href*=" + sectionId + "]").classList.remove("active");
            }
        });
        }

    },
}
</script>